(function (jedeyeGroupReservationModule) {
    "use strict";
    jedeyeGroupReservationModule(window.jQuery, window, document);

}(function ($, window, document) {

    /*************
     * Routed Functions
     */
    $(document).router({
        jedeye_rw_group_reservation_form: function ($targetElem) {

            function formReadyCheck() {
                if(
                    $('#pickupStation', $targetElem).val() != "" &&
                    $('#dropoffStation', $targetElem).val() != "" &&
                    $('#pickupDatePicker', $targetElem).val() != "" &&
                    $('#dropoffDatePicker', $targetElem).val() != "" &&
                    $('#pickupTimePicker', $targetElem).val() != "" &&
                    $('#dropoffTimePicker', $targetElem).val() != ""
                ) {
                    if($('.booking-form.main', $targetElem).data('auto-trigger') == '1'
                    && $('.booking-form.main', $targetElem).data('auto-lock-submit') != '1')
                        $(".booking-form.main", $targetElem).submit();
                }

            }

            $('#pickupStation', $targetElem).on("change", function (e) {
                if($('#pickupStation', $targetElem).val() == null) return;
                //pickupLocationData = $('#stationData_' + $('#pickupStation').val()).data('details')[0];
                $('#pickupDatePicker', $targetElem).prop('disabled', false);
                $('#pickupTimePicker', $targetElem).prop('disabled', false);
                formReadyCheck();
            });

            $('#dropoffStation', $targetElem).on("change", function (e) {
                if($('#dropoffStation', $targetElem).val() == null) return;
                //dropoffLocationData = $('#stationData_' + $('#dropoffStation').val()).data('details')[0];
                $('#dropoffDatePicker', $targetElem).prop('disabled', false);
                $('#dropoffTimePicker', $targetElem).prop('disabled', false);
                formReadyCheck();
            });

            function showPickupTimepicker() {
                $('#pickupTimePicker', $targetElem).prop('readonly', false);
                $('#pickupTimePicker', $targetElem).timepicker('show');
                $('#pickupTimePicker', $targetElem).prop('readonly', true);
            }

            function showDropoffTimepicker() {
                $('#dropoffTimePicker', $targetElem).prop('readonly', false);
                $('#dropoffTimePicker', $targetElem).timepicker('show');
                $('#dropoffTimePicker', $targetElem).prop('readonly', true);
            }

            // Set location data when location pickers are filled when the page loads
            if($('.booking-form #pickupStation', $targetElem).val() != "")
                $('.booking-form #pickupStation', $targetElem).trigger("change");

            if($('.booking-form #dropoffStation', $targetElem).val() != "")
                $('.booking-form #dropoffStation', $targetElem).trigger("change");

            var pickupDatePicker = null, dropoffDatePicker = null;

            if($('#pickupDatePicker', $targetElem).length > 0) {
                pickupDatePicker = new Pikaday({
                    field: $('#pickupDatePicker', $targetElem)[0],
                    firstDay: 0,
                    numberOfMonths: 1,
                    minDate: new Date(),
                    maxDate: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
                    format: $('body').data("date-format"),
                    bound: true,
                    yearRange: [2000, 2020],
                    onSelect: function (date) {
                        dropoffDatePicker.setMinDate(this.getDate());

                        if(dropoffDatePicker.getDate() < date) {
                            dropoffDatePicker.setDate(this.getDate());
                        }

                        dropoffDatePicker.gotoDate(this.getDate()); // Change current view
                    },
                    onClose: function () {
                        if(this._o.field.value != "") { // Check if field was not left empty
                            if ($('.booking-form.main', $targetElem).data('auto-trigger') == '1') {
                                if ($('#pickupTimePicker', $targetElem).val() == "") {
                                    $('#pickupTimePicker', $targetElem).data('force-showing', '1');
                                    showPickupTimepicker();
                                }
                            }
                            formReadyCheck();
                        }else{
                            $('.booking-form.main', $targetElem).data('auto-trigger', '0');
                        }
                    },
                    disableDayFn: function (date) {

                        if (typeof pickupLocationData === 'undefined' || pickupLocationData == null)
                            return false;

                        // Weekdays
                        if (pickupLocationData['WeekDayOpen'] == null || pickupLocationData['WeekDayOpen'] == "") {
                            if (date.getDay() >= 1 && date.getDay() <= 5)
                                return true;
                        }

                        if (pickupLocationData['SaturDayOpen'] == null || pickupLocationData['SaturDayOpen'] == "") {
                            if (date.getDay() == 6)
                                return true;
                        }

                        if (pickupLocationData['SunDayOpen'] == null || pickupLocationData['SunDayClose'] == "") {
                            if (date.getDay() == 0)
                                return true;
                        }

                        return false;

                    },
                    i18n: {
                        previousMonth: 'Previous Month',
                        nextMonth: 'Next Month',
                        months: moment.months(),
                        weekdays: moment.weekdays(),
                        weekdaysShort: moment.weekdaysShort()
                    }
                });
            }

            if($('#dropoffDatePicker', $targetElem).length > 0) {
                dropoffDatePicker = new Pikaday({
                    field: $('#dropoffDatePicker', $targetElem)[0],
                    firstDay: 0,
                    numberOfMonths: 1,
                    minDate: new Date(),
                    maxDate: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
                    format: $('body').data("date-format"),
                    bound: true,
                    yearRange: [2000, 2020],
                    onSelect: function (date) {
                        // field.value = picker.toString();
                        // pickupDatePicker.setMaxDate(this.getDate());
                    },
                    onClose: function () {
                        if(this._o.field.value != "") { // Check if field was not left empty
                            if ($('.booking-form.main', $targetElem).data('auto-trigger') == '1') {
                                if ($('#dropoffTimePicker', $targetElem).val() == "") {
                                    $('#dropoffTimePicker', $targetElem).data('force-showing', '1');

                                    showDropoffTimepicker();
                                    //$('#dropoffTimePicker').clockpicker('show');
                                }
                            }
                            formReadyCheck();
                        }else{
                            $('.booking-form.main', $targetElem).data('auto-trigger', '0');
                        }
                    },
                    disableDayFn: function (date) {

                        if (typeof dropoffLocationData === 'undefined' || dropoffLocationData == null)
                            return false;

                        // Weekdays
                        if (dropoffLocationData['WeekDayOpen'] == null || dropoffLocationData['WeekDayOpen'] == "") {
                            if (date.getDay() >= 1 && date.getDay() <= 5)
                                return true;
                        }

                        if (dropoffLocationData['SaturDayOpen'] == null || dropoffLocationData['SaturDayOpen'] == "") {
                            if (date.getDay() == 6)
                                return true;
                        }

                        if (dropoffLocationData['SunDayOpen'] == null || dropoffLocationData['SunDayClose'] == "") {
                            if (date.getDay() == 0)
                                return true;
                        }

                        return false;

                        //$datePicker.datebox({ blackDays : nonWorkingDays, minDays: 0 });
                    },
                    i18n: {
                        previousMonth: 'Previous Month',
                        nextMonth: 'Next Month',
                        months: moment.months(),
                        weekdays: moment.weekdays(),
                        weekdaysShort: moment.weekdaysShort()
                    }
                });
            }

            if($('.booking-form.main', $targetElem).length == 0) {
                // if(pickupDatePicker != null && $('#dropoffTimePicker', $targetElem).val() != '') pickupDatePicker.setMaxDate(dropoffDatePicker.getDate());
                if(dropoffDatePicker != null && $('#pickupTimePicker', $targetElem).val() != '') dropoffDatePicker.setMinDate(pickupDatePicker.getDate());
            }

            $('#pickupTimePicker, #dropoffTimePicker', $targetElem).each(function() {
                var $el = $(this);
                $el.timepicker({
                    timeFormat: $('body').data("time-format"),
                    disableTextInput: true,
                    scrollDefault: "10:00",
                    step: 15,
                    // minTime: "9:30am",
                    // maxTime: "18:30pm",
                    className: $el.attr('id') + "-timepicker"
                });

                $el.on('showTimepicker', function() {
                    $('.' + $el.attr('id') + '-timepicker').css('width', $el.closest('.time-field').width());
                });
            });

            $('#pickupTimePicker', $targetElem)
                .on('click', function(){
                    showPickupTimepicker();
                })
                .on('changeTime', function(){
                    if($('#pickupTimePicker', $targetElem).val() == "") {
                        $('.booking-form.main', $targetElem).data('auto-trigger', '0');
                    }else {
                        $('#pickupTimePicker', $targetElem).parents('.time-field:first').addClass('has-value');
                        if ($('.booking-form.main', $targetElem).data('auto-trigger') == '1') {
                            setTimeout(function() {
                                dropoffDatePicker.show();
                            }, 500);
                        }
                    }
                    formReadyCheck();
                });

            $('#dropoffTimePicker', $targetElem)
                .on('click', function(){
                    showDropoffTimepicker();
                })
                .on('changeTime', function(){
                    $('#dropoffTimePicker', $targetElem).parents('.time-field:first').addClass('has-value');
                    formReadyCheck();
                });


            // Auto trigger main form
            $('.booking-form.main #pickupStation', $targetElem).on("change", function (e) {
                if($('.booking-form.main', $targetElem).data('auto-trigger') == '1') {
                    $('.booking-form #dropoffStation', $targetElem).val($(this).val()).trigger("change");
                    if($('#pickupDatePicker', $targetElem).val() == '')
                        pickupDatePicker.show();
                }
            });

            $('.booking-form.main #pickupDatePicker', $targetElem).each(function() {
                if($('#pickupStation', $targetElem).val() == "") {
                    $('#pickupDatePicker', $targetElem).prop('disabled', true);
                    $('#pickupTimePicker', $targetElem).prop('disabled', true);
                }
                //if($('#pickupTimePicker').val() == "")
                //    $('#pickupTimePicker').prop('disabled', true);
            });

            $('.booking-form.main #dropoffDatePicker', $targetElem).each(function() {
                if($('#dropoffStation').val() == "") {
                    $('#dropoffDatePicker').prop('disabled', true);
                    $('#dropoffTimePicker').prop('disabled', true);
                }
                //if($('#dropoffTimePicker').val() == "")
                //    $('#dropoffTimePicker').prop('disabled', true);
            });
        },
    }, true);

}));